import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/src/css/mapbox-gl.css'

export default class Maps {
  constructor() {
    mapboxgl.accessToken =
      'pk.eyJ1IjoibmF0aGFuZnVzc25lciIsImEiOiJjaWpmc2J2bnIwMDJxdHRtNTJzbGFqeWJsIn0.kE_LBqdP38B3OsCVPPDmUg'

    const maps = document.querySelectorAll('.map')

    Array.from(maps).forEach((map, index) => {
      this.initMap(map, index)
    })
  }

  initMap(mapDiv, index) {
    mapDiv.id = `map-${index}`

    const latitude = mapDiv.dataset.latitude
    const longitude = mapDiv.dataset.longitude

    const map = new mapboxgl.Map({
      container: `map-${index}`,
      style: 'mapbox://styles/nathanfussner/cjzldd7qo1zvi1cmrgs6s47hx',
      attributionControl: false,
      center: [longitude, latitude],
      scrollZoom: false,
      zoom: 15,
    })

    var el = document.createElement('div')
    el.className = 'marker'

    new mapboxgl.Marker(el).setLngLat([longitude, latitude]).addTo(map)
  }
}
