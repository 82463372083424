export default class Menu {
  constructor() {
    this.hamburger = $('.hamburger__wrapper');
    this.nav = $('.header__nav');
    this.initMenu();
  }

  initMenu() {
    this.hamburger.on('click', () => {
      this.hamburger.children().toggleClass('active');
      this.nav.slideToggle(300);
    })
  }
}
