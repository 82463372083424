export default class FeaturedEvents {
  constructor() {
    const sliders = document.querySelectorAll('.featured-events__list')

    Array.from(sliders).forEach((slider) => {
      $(slider).slick({
        infinite: false,
        variableWidth: true,
      })
    })
  }
}
