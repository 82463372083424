export default class Search {
  constructor() {
    this.types = Array.from(document.querySelectorAll('.search__type'))

    let filters = []

    const filterButtons = document.querySelectorAll(
      '.search .explore__toggles__group button',
    )

    Array.from(filterButtons).forEach((button) => {
      button.addEventListener('click', () => {
        button.classList.toggle('active')

        if (button.classList.contains('active')) {
          filters.push(button.textContent)
        } else {
          const index = filters.indexOf(button.textContent)
          filters.splice(index, 1)
        }

        this.toggleTypes(filters)
      })
    })
  }

  toggleTypes(filters) {
    if (!filters.length) {
      this.types.forEach((type) => {
        type.classList.remove('hide')
      })
    } else {
      this.types.forEach((type) => {
        type.classList.add('hide')
      })

      filters.forEach((filter) => {
        const selectedType = document.querySelector(
          `.search__type[data-type="${filter}"]`,
        )
        selectedType.classList.remove('hide')
      })
    }
  }
}
