export default class BusinessCategoryAlternate {
  constructor() {
    const sliders = document.querySelectorAll('.business-category__slider--2')

    Array.from(sliders).forEach((slider) => {
      $(slider).slick({
        infinite: false,
        slidesToShow: 1.5,
        arrows: true,
        centerMode: false,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 1.1
            }
          },
          {
            breakpoint: 1080,
            settings: {
              slidesToShow: 1.3
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1.1
            }
          },
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 1
            }
          },
        ]
      })
    })
  }
}
