export default class Media {
  constructor() {
    const gallery = document.querySelectorAll('.media__gallery__slider')

    Array.from(gallery).forEach((slider) => {
      $(slider).slick({
        dots: true,
        arrows: false,
      })
    })
  }
}
