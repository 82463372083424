export default class BusinessCategory {
  constructor() {
    const sliders = document.querySelectorAll('.business-category__slider--1')

    Array.from(sliders).forEach((slider) => {
      $(slider).slick({
        infinite: false,
        slidesToShow: 4,
        arrows: true,
        centerMode: false,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 2.8,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2.2,
            },
          },
          {
            breakpoint: 540,
            settings: {
              slidesToShow: 1.2,
            },
          },
        ],
      })
    })
  }
}
