import 'core-js/stable'
import 'regenerator-runtime/runtime'
import objectFitImages from 'object-fit-images'
import objectFitVideos from 'object-fit-videos'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

objectFitImages()
objectFitVideos()

import Menu from './menu'

new Menu()

import BusinessCategory from './business-category'
import BusinessCategoryAlternate from './business-category-alternate'
import ChildrenSlider from './children-slider'
import FeaturedEvents from './featured-events'
import FeaturedGuides from './featured-guides'
import Formbuilder from './formbuilder'
import Header from './header'
import Maps from './maps'
import TeamMemberBio from './team-member-bio'
import Media from './media'
import Search from './search'
import Video from './video'

new BusinessCategory()
new BusinessCategoryAlternate()
new ChildrenSlider()
new FeaturedEvents()
new FeaturedGuides()
new Formbuilder()
new Header()
new Maps()
new Media()
new Search()
new TeamMemberBio()
new Video()
