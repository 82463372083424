export default class Header {
  constructor() {
    const mobileToggle = document.querySelector('.mobile-toggle button')
    const nav = document.querySelector('.app-header__nav')

    mobileToggle.addEventListener('click', () => {
      mobileToggle.classList.toggle('open')
      nav.classList.toggle('open')
    })

    const searchButton = document.querySelector(
      '.app-header__search .search-toggle',
    )
    const searchInput = document.querySelector(
      '.app-header__search input[type="text"]',
    )

    searchButton.addEventListener('click', () => {
      const parent = searchButton.parentElement.parentElement
      parent.classList.toggle('grow')

      if (parent.classList.contains('grow')) {
        searchInput.focus()
      } else {
        searchInput.blur()
      }
    })

    const childrenToggles = document.querySelectorAll('.children-toggle')
    Array.from(childrenToggles).forEach((toggle) => {
      toggle.addEventListener('click', () => {
        toggle.classList.toggle('open')
        $(toggle).next().slideToggle()
      })
    })
  }
}
