export default class FeaturedGuides {
  constructor() {
    const guides = document.querySelectorAll('.featured-guides__pages__slider')

    Array.from(guides).forEach((slider) => {
      $(slider).slick({
        arrows: true,
        infinite: false,
        variableWidth: true,
      })
    })
  }
}
