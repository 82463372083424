export default class ChildrenSlider {
  constructor() {
    document.addEventListener('DOMContentLoaded', function () {
      const sliders = document.querySelectorAll('.children-slider__list')
      Array.from(sliders).forEach((slider) => {
        $(slider)
          .not('.slick-initialized')
          .slick({
            infinite: false,
            variableWidth: true,
            slidesToShow: 3,
            responsive: [
              {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 960,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          })
      })
    })
  }
}
